// import React from "react";
// import img from "../../assets/access.png"
// import "./Access.css";

// const Access = () => {
//   return (
//     <div className="access-main w-full pt-28 pb-24">
//       <h1 className="access-h text-center font-manrope">
//         Get Access to Secure and
//       </h1>
//       <h2 className="access-h text-center font-manrope">Low Volatile Yields</h2>
//       <p className="access-p text-center font-manrope mt-8">
//         Transforming Inefficient Invoices into On-chain Financing
//       </p>
//       <p className="access-p text-center font-manrope">
//         Instruments via Know Your Invoice (KYI)
//       </p>
//       <div className="flex justify-center items-center mt-16">
//         <button className="access-btn px-6 py-3 text-white">Get Started</button>
//       </div>
//       <div className="flex justify-center items-center mt-20">
//         <img src={img} alt="" style={{height: "53px", width: "303px"}} />
//       </div>
//     </div>
//   );
// };

// export default Access;

import React from "react";
import { motion } from "framer-motion";
import img from "../../assets/access.png";
import "./Access.css";

const Access = () => {
  return (
    <div className="access-main w-full pt-32 pb-28 px-4 sm:px-6 lg:px-8 ">
      {/* Heading Section */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ once: true }}
      >
        <h1 className="access-h  text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center font-manrope">
          Get Access to Secure and
        </h1>
        <h2 className="access-h  text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center font-manrope">
          Low Volatile Yields
        </h2>
      </motion.div>

      {/* Description Section */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        viewport={{ once: true }}
      >
        <p className="access-p  text-base sm:text-lg md:text-xl text-center font-manrope mt-8">
          Transforming Inefficient Invoices into On-chain Financing
        </p>
        <p className="access-p  text-base sm:text-lg md:text-xl text-center font-manrope">
          Instruments via Know Your Invoice (KYI)
        </p>
      </motion.div>

      {/* Button Section */}
      <motion.div
        className="flex justify-center items-center mt-16"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
        viewport={{ once: true }}
      >
        <a
          href="https://testnet.imdefa.com"
          target="_blank"
          rel="noopener noreferrer"
          className="nav-btn px-6 py-3 md:py-4 text-white rounded-full bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700 transition-transform transform hover:scale-110 duration-300"
        >
          Join Testnet
        </a>
      </motion.div>

      {/* Image Section */}
      <motion.div
        className="flex justify-center items-center mt-20"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
        viewport={{ once: true }}
      >
        <img
          src={img}
          alt="Access Image"
          className="h-12 w-auto object-contain"
        />
      </motion.div>
    </div>
  );
};

export default Access;
