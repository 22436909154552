import { motion } from "framer-motion";
import img from "../../assets/hero.png";
import "./Hero.css";

export default function Hero() {
  return (
    <div className="max-w-6xl mx-auto text-white mt-20 sm:py-16 md:pt-20  px-4 sm:px-6 md:px-8">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between lg:space-x-12 xl:space-x-24">
        {/* Text Section */}
        <motion.div
          className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:mt-0 mt-16"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <h1 className="hero-text font-manrope text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
            A new{" "}
            <p className="hero-text font-bold mb-4">
              DeFi Primitive
            </p>
          </h1>
          <p className="hero-text2 font-manrope mt-4 mb-9 sm:mt-6 md:mt-8 text-lg sm:text-xl md:text-2xl">
            DeFa is Financially Responsible
          </p>

          <a
            href="https://testnet.imdefa.com"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-btn px-6 py-3 md:py-4 text-white rounded-full bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700 transition-transform transform hover:scale-110 duration-300"
          >
            Join Testnet
          </a>

        </motion.div>

        {/* Image Section */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          whileHover={{
            scale: 1.05,
            transition: { duration: 0.4, ease: "easeOut" },
          }}
          className="flex-shrink-0 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-auto"
        >
          <img
            src={img}
            alt="Hero Image"
            className="w-full h-auto lg:w-[498px] lg:h-[575px] object-contain"
          />
        </motion.div>
      </div>
    </div>
  );
}