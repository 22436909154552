// // import { Link } from "react-router-dom"
// // import img from '../../assets/logo.png';
// // import "./Navbar.css";

// // export default function Navbar() {
// //   return (
// //     <nav className=" max-w-6xl pt-14 mx-auto text-white py-4 px-6">
// //       <div className="container mx-auto flex justify-between items-center">
// //         <img src={img} alt="defa" style={{width: "193px" , height: "37px" }} />
// //         <ul className="flex space-x-14">
// //           <li><Link href="/about-us" className="nav-text font-manrope">About us</Link></li>
// //           <li><Link href="/land" className="nav-text font-manrope">Lend</Link></li>
// //           <li><Link href="/borrow" className="nav-text font-manrope">Borrow</Link></li>
// //           <li>
// //             <Link href="/join-community" className="nav-btn px-6 py-3 rounded-full">
// //               Join Community
// //             </Link>
// //           </li>
// //         </ul>
// //       </div>
// //     </nav>
// //   )
// // }

// // import { useState, useEffect } from "react";
// // import { Link } from "react-router-dom";
// // import { motion } from "framer-motion";
// // import { FaBars, FaTimes } from "react-icons/fa";
// // import img from "../../assets/logo.png";

// // export default function Navbar() {
// //   const [isOpen, setIsOpen] = useState(false);
// //   const [isScrolled, setIsScrolled] = useState(false);

// //   // Toggle mobile menu
// //   const toggleMenu = () => {
// //     setIsOpen(!isOpen);
// //   };

// //   // Handle navbar shrink on scroll
// //   useEffect(() => {
// //     const handleScroll = () => {
// //       if (window.scrollY > 50) {
// //         setIsScrolled(true);
// //       } else {
// //         setIsScrolled(false);
// //       }
// //     };
// //     window.addEventListener("scroll", handleScroll);
// //     return () => {
// //       window.removeEventListener("scroll", handleScroll);
// //     };
// //   }, []);

// //   // Animation Variants
// //   const navVariants = {
// //     hidden: { opacity: 0, y: -50 },
// //     visible: { opacity: 1, y: 0 },
// //   };

// //   const menuVariants = {
// //     hidden: { opacity: 0, x: "-100%" },
// //     visible: { opacity: 1, x: 0 },
// //   };

// //   return (
// //     <motion.nav
// //       className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
// //         isScrolled ? "bg-blue-700 shadow-lg py-2" : "bg-transparent py-4"
// //       }`}
// //       initial="hidden"
// //       animate="visible"
// //       variants={navVariants}
// //     >
// //       <div className="max-w-6xl mx-auto px-4 md:px-6 flex justify-between items-center">
// //         {/* Logo */}
// //         <img
// //           src={img}
// //           alt="defa"
// //           className={`transition-all duration-300 ${
// //             isScrolled ? "w-32" : "w-48"
// //           }`}
// //         />

// //         {/* Desktop Menu */}
// //         <ul className="hidden md:flex space-x-10 items-center">
// //           <li>
// //             <Link
// //               to="/about-us"
// //               className="nav-text hover:text-blue-300 transition duration-300"
// //             >
// //               About us
// //             </Link>
// //           </li>
// //           <li>
// //             <Link
// //               to="/lend"
// //               className="nav-text hover:text-blue-300 transition duration-300"
// //             >
// //               Lend
// //             </Link>
// //           </li>
// //           <li>
// //             <Link
// //               to="/borrow"
// //               className="nav-text hover:text-blue-300 transition duration-300"
// //             >
// //               Borrow
// //             </Link>
// //           </li>
// //           <li>
// //             <Link
// //               to="/join-community"
// //               className="nav-btn px-6 py-2 rounded-full text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-400 hover:to-blue-600 transition duration-300"
// //             >
// //               Join Community
// //             </Link>
// //           </li>
// //         </ul>

// //         {/* Mobile Menu Icon */}
// //         <div className="md:hidden">
// //           {isOpen ? (
// //             <FaTimes
// //               onClick={toggleMenu}
// //               className="text-white text-2xl cursor-pointer"
// //             />
// //           ) : (
// //             <FaBars
// //               onClick={toggleMenu}
// //               className="text-white text-2xl cursor-pointer"
// //             />
// //           )}
// //         </div>

// //         {/* Mobile Menu */}
// //         <motion.div
// //           className={`fixed top-0 left-0 h-full w-3/4 bg-blue-900 text-white z-40 flex flex-col items-start justify-center px-8 py-4 transition-transform duration-300 transform ${
// //             isOpen ? "translate-x-0" : "-translate-x-full"
// //           }`}
// //           initial="hidden"
// //           animate={isOpen ? "visible" : "hidden"}
// //           variants={menuVariants}
// //         >
// //           <ul className="space-y-6">
// //             <li>
// //               <Link
// //                 to="/about-us"
// //                 onClick={toggleMenu}
// //                 className="text-lg font-semibold hover:text-blue-300 transition duration-300"
// //               >
// //                 About us
// //               </Link>
// //             </li>
// //             <li>
// //               <Link
// //                 to="/lend"
// //                 onClick={toggleMenu}
// //                 className="text-lg font-semibold hover:text-blue-300 transition duration-300"
// //               >
// //                 Lend
// //               </Link>
// //             </li>
// //             <li>
// //               <Link
// //                 to="/borrow"
// //                 onClick={toggleMenu}
// //                 className="text-lg font-semibold hover:text-blue-300 transition duration-300"
// //               >
// //                 Borrow
// //               </Link>
// //             </li>
// //             <li>
// //               <Link
// //                 to="/join-community"
// //                 onClick={toggleMenu}
// //                 className="text-lg font-semibold bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-400 hover:to-blue-600 px-4 py-2 rounded-full transition duration-300"
// //               >
// //                 Join Community
// //               </Link>
// //             </li>
// //           </ul>
// //         </motion.div>
// //       </div>
// //     </motion.nav>
// //   );
// // }
// import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { motion } from "framer-motion";
// import { FaBars, FaTimes } from "react-icons/fa";
// import img from "../../assets/logo.png";

// export default function Navbar() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);

//   // Toggle mobile menu
//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   // Handle navbar shrink on scroll
//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 50) {
//         setIsScrolled(true);
//       } else {
//         setIsScrolled(false);
//       }
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   // Animation Variants
//   const navVariants = {
//     hidden: { opacity: 0, y: -50 },
//     visible: { opacity: 1, y: 0 },
//   };

//   const menuVariants = {
//     hidden: { opacity: 0, x: "-100%" },
//     visible: { opacity: 1, x: 0 },
//   };

//   return (
//     <motion.nav
//       className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 custom-font ${
//         isScrolled ? "bg-[#0022B2] shadow-lg py-5" : "bg-[#250BC8] py-8" // Increased spacing from the top
//       }`}
//       initial="hidden"
//       animate="visible"
//       variants={navVariants}
//     >
//       <div className="max-w-7xl mx-auto px-4 md:px-6 flex justify-between items-center ">
//         {/* Logo */}
//         <Link to="/">
//           <img
//             src={img}
//             alt="defa"
//             className={`transition-all duration-300 ${
//               isScrolled ? "w-36" : "w-48"
//             }`}
//           />
//         </Link>

//         {/* Desktop Menu */}
//         <ul className="hidden md:flex space-x-12 items-center font-semibold text-white">
//           {" "}
//           {/* Increased spacing between links */}
//           <li>
//             <Link
//               to="/about-us"
//               className="nav-text lg:text-xl text-lg hover:text-blue-300 transition duration-300"
//             >
//               About us
//             </Link>
//           </li>
//           <li>
//             <a
//               href="https://linktr.ee/invoicemate_official"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="nav-btn px-6 py-3 md:py-4 text-white rounded-full bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700 transition-transform transform hover:scale-110 duration-300"
//             >
//               Join Community
//             </a>
//           </li>
//         </ul>

//         {/* Mobile Menu Icon */}
//         <div className="md:hidden z-[999]">
//           {isOpen ? (
//             <FaTimes
//               onClick={toggleMenu}
//               className="text-white text-2xl cursor-pointer"
//             />
//           ) : (
//             <FaBars
//               onClick={toggleMenu}
//               className="text-white text-2xl cursor-pointer"
//             />
//           )}
//         </div>

//         {/* Mobile Menu */}
//         <motion.div
//           className={`fixed top-0 left-0 h-screen w-screen bg-blue-900 text-white z-40 flex flex-col items-center justify-center space-y-10 text-lg transition-transform duration-300 transform ${
//             isOpen ? "translate-x-0" : "-translate-x-full"
//           }`}
//           initial="hidden"
//           animate={isOpen ? "visible" : "hidden"}
//           variants={menuVariants}
//         >
//           <ul className="space-y-10 text-center font-semibold text-xl mt-10">
//             {" "}
//             {/* Added margin-top to shift menu items upwards */}
//             <li>
//               <Link
//                 to="/about-us"
//                 onClick={toggleMenu}
//                 className="hover:text-blue-300 transition duration-300"
//               >
//                 About us
//               </Link>
//             </li>
//             <li>
//               <a
//                 href="https://linktr.ee/defa_offical"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 onClick={toggleMenu}
//                 className="px-6 py-4 text-white rounded-full bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-400 hover:to-blue-600 transition-transform transform hover:scale-110 duration-300"
//               >
//                 Join Community
//               </a>
//             </li>
//           </ul>
//         </motion.div>
//       </div>
//     </motion.nav>
//   );
// }

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaBars, FaTimes } from "react-icons/fa";
import img from "../../assets/logo.png";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Toggle mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Handle navbar shrink on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Animation Variants
  const navVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  const menuVariants = {
    hidden: { opacity: 0, x: "-100%" },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <>
      {/* Top Banner Section */}
      <div className="w-full fixed top-0 left-0 z-50 bg-gradient-to-r from-purple-500 to-purple-700 text-white text-center py-2 px-4 cursor-pointer">
        <a
          href="https://mirror.xyz/0x2e94ce8BDfA2Db9e01C57991B82eA506b1Bf2762/LekeKizTj6jUnbqrXBLQry-KrgWVHj_OY2nRhk4kSn8?referrerAddress=0x2e94ce8BDfA2Db9e01C57991B82eA506b1Bf2762"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center text-base md:text-lg font-semibold max-w-screen-xl mx-auto space-x-2"
        >
          <span>
          DeFa Testnet Is Live: Dive In, Earn Factoids, and Help Us Refine Our Real-World DeFi Vision
          </span>
          <span className="text-xl md:text-2xl">&gt;</span>
        </a>
      </div>

      {/* Navbar */}
      <motion.nav
        className={`fixed top-12 left-0 w-full z-40 transition-all duration-300 custom-font ${isScrolled ? "bg-[#0022B2] shadow-lg md:py-5 py-7" : "bg-[#250BC8] py-8"
          }`}
        initial="hidden"
        animate="visible"
        variants={navVariants}
      >
        <div className="max-w-7xl mx-auto px-4 md:px-6 flex justify-between items-center">
          {/* Logo */}
          <Link to="/">
            <img
              src={img}
              alt="defa"
              className={`transition-all duration-300 ${isScrolled ? "w-36" : "w-48"
                }`}
            />
          </Link>

          {/* Desktop Menu */}
          <ul className="hidden md:flex space-x-12 items-center font-semibold text-white">
            <li>
              <Link
                to="/about-us"
                className="nav-text lg:text-xl text-lg hover:text-blue-300 transition duration-300"
              >
                About us
              </Link>
            </li>
            <li>
              <a
                href="https://testnet.imdefa.com"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-btn px-6 py-3 md:py-4 text-white rounded-full bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700 transition-transform transform hover:scale-110 duration-300"
              >
                Join Testnet
              </a>
            </li>
          </ul>

          {/* Mobile Menu Icon */}
          <div className="md:hidden z-[999]">
            {isOpen ? (
              <FaTimes
                onClick={toggleMenu}
                className="text-white text-2xl cursor-pointer"
              />
            ) : (
              <FaBars
                onClick={toggleMenu}
                className="text-white text-2xl cursor-pointer"
              />
            )}
          </div>

          {/* Mobile Menu */}
          <motion.div
            className={`fixed top-0 left-0 h-screen w-screen bg-blue-900 text-white z-40 flex flex-col items-center justify-center space-y-10 text-lg transition-transform duration-300 transform ${isOpen ? "translate-x-0" : "-translate-x-full"
              }`}
            initial="hidden"
            animate={isOpen ? "visible" : "hidden"}
            variants={menuVariants}
          >
            <ul className="space-y-10 text-center font-semibold text-xl mt-10">
              <li>
                <Link
                  to="/about-us"
                  onClick={toggleMenu}
                  className="hover:text-blue-300 transition duration-300"
                >
                  About us
                </Link>
              </li>
              <li>
                <a
                  href="https://linktr.ee/defa_offical"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={toggleMenu}
                  className="px-6 py-4 text-white rounded-full bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-400 hover:to-blue-600 transition-transform transform hover:scale-110 duration-300"
                >
                  Join Community
                </a>
              </li>
            </ul>
          </motion.div>
        </div>
      </motion.nav>
    </>
  );
}
